import styled from '@emotion/styled';
import { Button, Checkbox, Form, Input, Row, Select } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppContext } from 'Context/AppContext';
import { ProposalType, RequestType } from 'constant/enum';
import { orgPath } from 'constant/path';
import { ResponseDataType, getRequest, postRequest } from 'api';
import styles from '../stream.module.css';
import { getFullInfoInDepartment, normalizeText } from 'utils';
import { Avatar } from 'Components';

const enum CreateType {
  PROPOSAL = 'proposal',
  REQUEST = 'request',
  TASK = 'task',
}

const CREATE_INFO = {
  [CreateType.PROPOSAL]: {
    en: 'proposal',
    vi: 'đề xuất',
    defaultRequestUrl: '',
    defaultStreamType: ProposalType.GENERAL,
    streamTypeList: [
      {
        value: ProposalType.GENERAL,
        label: 'Đề xuất chung',
        requestUrl: `/${orgPath}/create-general-proposal`,
      },
      {
        value: ProposalType.DYNAMIC as number,
        label: 'Đề xuất linh hoạt',
        requestUrl: `/${orgPath}/create-dynamic-proposal`,
      },
    ],
  },
  [CreateType.REQUEST]: {
    en: 'request',
    vi: 'đề nghị',
    defaultRequestUrl: '',
    defaultStreamType: RequestType.PAYMENT,
    streamTypeList: [
      {
        value: RequestType.PAYMENT,
        label: 'Đề nghị thanh toán',
        requestUrl: `/${orgPath}/create-payment-request`,
      },
      {
        value: RequestType.ADVANCE,
        label: 'Đề nghị tạm ứng',
        requestUrl: `/${orgPath}/create-payment-request`,
      },
      {
        value: RequestType.REIMBURSEMENT,
        label: 'Đề nghị hoàn ứng',
        requestUrl: `/${orgPath}/create-payment-request`,
      },
    ],
  },
  [CreateType.TASK]: {
    en: 'task',
    vi: 'task',
    defaultRequestUrl: `/${orgPath}/create-task`,
    defaultStreamType: undefined,
    streamTypeList: undefined,
  },
};

interface CreateInfoDataType {
  groupList: { id: string; name: string; typeCode: string }[];
}

interface OptionList {
  value: string;
  label: string;
  typeCode: string;
}

interface MemberDepartmentInfo {
  name: string;
  namecode: string;
  isManager: boolean;
}

interface MemberInfoInList {
  id: string;
  name: string;
  avatar: string;
  departmentInfo: MemberDepartmentInfo;
}

export const CreateStream = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { currentOrg } = useAppContext();
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [groupOptions, setGroupOptions] = useState<OptionList[]>([]);
  const [createType, setCreateType] = useState<CreateType>(CreateType.PROPOSAL);
  const [memberList, setMemberList] = useState<MemberInfoInList[]>([]);

  useEffect(() => {
    const getCreateInfo = async () => {
      const res: ResponseDataType<CreateInfoDataType> = await getRequest(
        `/${orgPath}/${currentOrg}/get-create-info`,
      );

      if (res.code) {
        toast.error(res.message);
      } else {
        const groupOptionList = res.data?.groupList.map((e) => ({
          value: e.id,
          label: e.name,
          typeCode: e.typeCode,
        }));
        setGroupOptions(groupOptionList || []);
      }
    };

    getCreateInfo();
  }, [currentOrg]);

  useEffect(() => {
    const getMemberList = async () => {
      const response: ResponseDataType<{
        managerList: string[];
        memberList: MemberInfoInList[];
      }> = await getRequest(
        `/organization/get-org-member-list?orgId=${currentOrg}&type=all&getPosition=true`,
      );

      if (!response.data) {
        setMemberList([]);
        return;
      }

      const resData = response.data;
      const sortedMemberList = resData.memberList.reduce(
        (curList: MemberInfoInList[], member) => {
          if (resData.managerList.includes(member.id)) {
            curList.unshift(member);
          } else {
            curList.push(member);
          }
          return curList;
        },
        [],
      );
      setMemberList(sortedMemberList);
    };

    if (createType === CreateType.TASK && !memberList?.length) {
      getMemberList();
    }
  }, [currentOrg, createType, memberList]);

  useEffect(() => {
    form.setFieldsValue({
      type: CREATE_INFO[createType].defaultStreamType,
      assignee: undefined,
      description: undefined,
      content: undefined,
      groupList: undefined,
    });
  }, [form, createType]);

  const handleCreateStream = async (value: {
    description: string;
    type?: number;
    groupList: string[];
    content: string;
    assignee?: string[];
  }) => {
    setIsBtnLoading(true);
    const { description, type, groupList, content, assignee } = value;
    const createInfo = CREATE_INFO[createType]?.streamTypeList?.find(
      (e) => e.value === value.type,
    );
    const response: ResponseDataType<string> = await postRequest(
      createInfo
        ? createInfo.requestUrl
        : CREATE_INFO[createType].defaultRequestUrl,
      {
        organizationId: currentOrg,
        description,
        content,
        type,
        assignee: assignee || [],
        groupList: groupList || [],
        groupTypeCode: groupOptions?.find(
          (group) => group.value === value.groupList?.[0],
        )?.typeCode,
      },
    );

    if (response.code) {
      toast.error(response.message);
    } else {
      toast.success(`Tạo ${CREATE_INFO[createType].vi} thành công`);
      navigate(`/${CREATE_INFO[createType].en}-detail/${response.data}`);
    }
    setIsBtnLoading(false);
  };

  return (
    <div className={styles.create_stream_page}>
      <Row justify='space-between' style={{ width: 300, marginBottom: 20 }}>
        {Object.keys(CREATE_INFO).map((createInfoKey) => (
          <CreateTypeTab
            key={createInfoKey}
            style={
              createType.toString() === createInfoKey
                ? { borderColor: '#1968FC' }
                : {}
            }
            onClick={() => setCreateType(createInfoKey as CreateType)}
          >
            {CREATE_INFO[createInfoKey as CreateType].vi}
          </CreateTypeTab>
        ))}
      </Row>
      <Form form={form} onFinish={handleCreateStream} style={{ width: 300 }}>
        {[CreateType.PROPOSAL, CreateType.REQUEST].includes(createType) && (
          <>
            <div className={styles.form_label}>
              Chọn loại {CREATE_INFO[createType].vi}
            </div>
            <Form.Item name='type'>
              <Select
                options={CREATE_INFO[createType].streamTypeList}
                style={{ height: 40 }}
                labelRender={(label) => (
                  <span style={{ padding: 5 }}>{label.label}</span>
                )}
              />
            </Form.Item>
          </>
        )}
        {createType === CreateType.TASK && (
          <>
            <div className={styles.form_label}>Giao cho</div>
            <Form.Item name='assignee' style={{ marginBottom: 0 }}>
              <SelectMember
                mode='multiple'
                placeholder='Người thực hiện'
                optionFilterProp='label'
                showSearch={true}
                filterOption={(input, option) =>
                  normalizeText(option?.label?.toString()).includes(
                    normalizeText(input),
                  ) ||
                  normalizeText(option?.key?.toString()).includes(
                    normalizeText(input),
                  )
                }
              >
                {memberList.map((member, index) => {
                  const memberPosition = getFullInfoInDepartment({
                    isOldInfo: false,
                    isManager: member.departmentInfo.isManager,
                    department: { ...member.departmentInfo },
                  });

                  return (
                    <Select.Option
                      key={`${index}-${memberPosition}`}
                      value={member.id}
                      label={member.name}
                    >
                      <Row>
                        <Avatar
                          src={member.avatar}
                          style={{ width: 30, height: 30, borderRadius: '50%' }}
                        />
                        <div style={{ lineHeight: 'normal', marginLeft: 10 }}>
                          <div>{member.name}</div>
                          <div style={{ fontSize: 11, fontWeight: 300 }}>
                            {memberPosition}
                          </div>
                        </div>
                      </Row>
                    </Select.Option>
                  );
                })}
              </SelectMember>
            </Form.Item>
          </>
        )}
        <div className={styles.form_label}>Tiêu đề</div>
        <Form.Item name='description'>
          <Input.TextArea style={{ height: 30 }} placeholder={`Tiêu đề `} />
        </Form.Item>
        <div className={styles.form_label}>
          Nội dung {CREATE_INFO[createType].vi}
        </div>
        <Form.Item
          name='content'
          rules={[
            {
              required: true,
              message: `Cần nhập nội dung ${CREATE_INFO[createType].vi}!`,
            },
          ]}
        >
          <Input.TextArea
            style={{ minHeight: 100 }}
            placeholder={`Nội dung ${CREATE_INFO[createType].vi}`}
          />
        </Form.Item>
        <Form.Item
          name='groupList'
          rules={[
            () => ({
              validator(_, value) {
                if (!value || value.length === 0) {
                  return Promise.reject('Cần chọn 1 group');
                }

                if (value.length > 1) {
                  return Promise.reject('Chỉ được chọn 1 group');
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Checkbox.Group options={groupOptions} />
        </Form.Item>
        <Button
          loading={isBtnLoading}
          type='primary'
          htmlType='submit'
          className={styles.form_button_submit}
        >
          Thêm {CREATE_INFO[createType].vi}
        </Button>
      </Form>
    </div>
  );
};

const CreateTypeTab = styled.div`
  border-bottom: solid white 2px;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  text-transform: capitalize;
`;
const SelectMember = styled(Select)`
  .ant-select-selector {
    min-height: 40px;
  }

  .ant-select-selection-item {
    height: max-content;
  }

  .ant-select-selection-item-content {
    height: max-content;
    padding: 3px 0;
    margin-inline-end: 10px !important;
  }
`;
